import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import VoCarousel from "../components/VoCarousel"
import AudioPlayer from "../components/AudioPlayer"
import VoNav from "../components/VoNav"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import logo from "../images/vo-logo-inv.svg"

const Wrapper = styled.div`
  min-height: 100%;
  background: #107d83;

  &:focus {
    outline: 0;
  }

  h2 {
    margin: 4rem 0 1rem;
    padding: 0 1rem;
    font: 2rem/1 ${props => props.theme.headingFont};
    text-align: center;
    color: white;
    word-break: break-word;
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: white;
  }

  a {
    color: white;
  }

  @media (min-width: 667px) {
    h2 {
      margin-top: 2.5rem;
      padding: 0 7rem;
      font-size: 2.35rem;
    }
  }
`

const Logo = styled.div`
  position: fixed;
  top: 0;
  left: 1.75rem;
  z-index: 10;

  img {
    width: 4.125rem;
    height: 3.5rem;
  }
`

const TextAndAudioContainer = styled.div`
  width: 100%;
  max-width: 32.5rem;
  margin: 0 auto 3rem;
  padding: 0 1.625rem;

  @media (min-width: 667px) {
    position: relative;
    width: 78%;
    max-width: 40.625rem;
    min-height: 40px;
    padding-left: 9.875rem;
  }
`

const StyledAudioPlayer = styled(AudioPlayer)`
  width: 6.75rem;
  margin-bottom: 1rem;

  @media (min-width: 667px) {
   position: absolute;
   top: 0.3125rem;
   left: 1.625rem;
  }
`

const Nav = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;
  margin-top: auto;
  padding: 0 1.625rem 1rem 1.625rem;

  @media (min-width: 950px) and (min-height: 850px), (min-height: 1024px) {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`

export default function VoTemplate({
  data: { item: { title, images, sound, text }, prevItem, nextItem },
  pageContext: { lang, pagePath },
}) {
  const intl = useIntl()
  const getFileType = (filename) => filename && filename.split(".").slice(-1)[0].toLowerCase()
  let shareImage = (images || []).find((x) => x.isThumbnail)
  let shareImageFileType = shareImage && getFileType(shareImage.image)

  if (!shareImage || shareImageFileType === "svg") {
    shareImage = (images || []).find((x) => {
      const fileType = getFileType(x.image)
      return fileType === "jpg" || fileType === "jpeg" || fileType === "png"
    })
    shareImageFileType = shareImage && getFileType(shareImage.image)
  }

  return (
    <>
      <Wrapper>
        <NwHelmet
          lang={lang}
          path={pagePath}
          title={title}
          image={shareImage && {
            url: `${shareImage.image}?nf_resize=smartcrop&w=1200&h=630`,
            type: `image/${shareImageFileType === "jpg" ? "jpeg" : shareImageFileType}`,
            alt: shareImage.altText,
            width: 1200,
            height: 630,
          }}
        />
        <Header
          lang={lang}
          title={intl.formatMessage({ id: "whatIsWhat" })}
          color="#107d83"
          room={4}
          roomSymbol={logo}
          roomSymbolNoMargin={true}
        />
        <h2>{title}</h2>
        <VoCarousel images={images} />
        <TextAndAudioContainer>
          {sound &&
            <StyledAudioPlayer src={sound} progressBar={false} />
          }
          <div dangerouslySetInnerHTML={{ __html: get(text, "html") }} />
        </TextAndAudioContainer>
        <Nav>
          {prevItem ?
            <VoNav
              lang={lang}
              title={prevItem.title}
              image={prevItem.images[0].image}
              prev
            />
            :
            <span />
          }
          {nextItem ?
            <VoNav
              lang={lang}
              title={nextItem.title}
              image={nextItem.images[0].image}
            />
            :
            <span />
          }
        </Nav>
      </Wrapper>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </>
  )
}

export const pageQuery = graphql`
  query($lang: String, $order: Float, $prevItem: Float, $nextItem: Float) {
    item: voYaml(lang: { eq: $lang }, order: { eq: $order }) {
      title
      text {
        html
      }
      sound
      images {
        image
        altText
        isThumbnail
      }
    }
    prevItem: voYaml(lang: { eq: $lang }, order: { eq: $prevItem }) {
      title
      images {
        image
        altText
      }
    }
    nextItem: voYaml(lang: { eq: $lang }, order: { eq: $nextItem }) {
      title
      images {
        image
        altText
      }
    }
  }
`
