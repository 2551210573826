import React, { useEffect, useState } from "react"
import Flickity from "react-flickity-component"
import styled from "styled-components"

import flickityA11y from "../utils/flickity-a11y"

const StyledFlickity = styled(Flickity)`
  /*! Flickity v2.1.2
  https://flickity.metafizzy.co
  ---------------------------------------------- */

  overflow: hidden;

  &:focus {
    outline: 0;
  }

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-page-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 1.25rem 0 1rem;
    list-style: none;
    cursor: pointer;

    .dot {
      width: 0.75rem;
      height: 0.75rem;
      margin: 0 0.3125rem;
      border-radius: 50%;
      background: #50a5a8;
      text-indent: -9999px;
      transition: background 0.2s ease;

      &.is-selected {
        background: white;
      }
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: ${props => props.halfWidth ? "50%" : "100%"};
  height: 0;
  padding-top: ${props => props.halfWidth ? "35%" : "70%"};
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
  }
`

let voFlkty
const hasWindow = typeof window !== "undefined"

export default function VoCarousel({ images }) {
  const LARGE_BREAKPOINT = 667
  const imagesWithId = (images || []).map((image, index) => ({ ...image, id: index }))
  const [windowWidth, setWindowWidth] = useState(hasWindow ? window.innerWidth : 0)
  const flickityOptions = {
    prevNextButtons: false,
    groupCells: windowWidth >= LARGE_BREAKPOINT ? 2 : 1,
    on: {
      ready: () => flickityA11y(true, false, true),
      change: () => flickityA11y(false, false, true),
    },
  }

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  const getResizeString = (w) => `?nf_resize=fit&w=${w}`

  useEffect(() => {
    if (voFlkty) {
      voFlkty.select(0, false, true)
    }
  }, [images])

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener("resize", updateWindowWidth)
      return () => window.removeEventListener("resize", updateWindowWidth)
    }
  })

  useEffect(() => {
    if (voFlkty) {
      voFlkty.option({ groupCells: windowWidth >= LARGE_BREAKPOINT ? 2 : 1 })
    }
  }, [windowWidth])

  return (
    <StyledFlickity
      options={flickityOptions}
      flickityRef={c => voFlkty = c}
    >
      {imagesWithId.map((image) => {
        const encodedImage = encodeURI(image.image)

        return (
          <ImageWrapper key={image.id} halfWidth={windowWidth >= 667}>
            {image.isSvg ?
              <img src={encodedImage} alt={image.altText} />
            :
              <img
                src={`${encodedImage}${getResizeString(840)}`}
                alt={image.altText}
                srcSet={`${encodedImage}${getResizeString(840)} 420w, ${encodedImage}${getResizeString(1332)} 666w, ${encodedImage}${getResizeString(800)} 800w, ${encodedImage}${getResizeString(1024)} 1024w, ${encodedImage}${getResizeString(1500)} 1500w`}
                sizes="(min-width: 667px) 50vw, 100vw"
              />
            }
          </ImageWrapper>
        )
      })}
    </StyledFlickity>
  )
}
