import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import getSlugFromString from "../utils/get-slug-from-string"

import Arrow from "./icons/Arrow"

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8.375rem;
  text-decoration: none;
  text-align: center;

  .label {
    width: 8.375rem;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font: 0.8125rem/1.3 ${props => props.theme.headingFont};
    color: white;
  }

  .image {
    display: block;
    width: 3.75rem;
    height: 3.75rem;
    border: 0.125rem solid white;
    border-radius: 50%;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  svg {
    position: absolute;
    top: 0.8125rem;
    width: 2rem;
    height: 2rem;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.prev {
    svg {
      transform: rotate(180deg);
      left: 0;
    }
  }

  &.next {
    svg {
      right: 0;
    }
  }
`

const VoNav = ({ lang, title, image, prev }) => {
  return (
    <StyledLink
      to={`/${lang}/${getSlugFromString(title)}`}
      className={`nav-link ${prev ? "prev" : "next"}`}
    >
      <span
        className="image"
        style={{
          backgroundImage: image ? `url(${encodeURI(image)}${image.toLowerCase().endsWith(".svg") ? "" : "?nf_resize=fit&w=120"})` : "none",
        }}
      />
      {prev ?
        <Arrow className={prev && `prev`} />
        :
        <Arrow className={prev && `right`} />
      }
      <span className="label">{title}</span>
    </StyledLink>
  )
}

export default VoNav
